<template>
  <div>
    <hero-bar>
      {{ heroTitle }}
       <b-button slot="right"
        label="Editar Status"
        @click="isComponentModalActive = true"
        class="mr-2"
      />
      <b-button
        slot="right"
        native-type="button"
        type="is-info"
        @click="PODownload"
        :loading="loadingPO"
      >
        Baixar planilha PO
      </b-button>
    </hero-bar>
    <section class="section is-main-section">
        <card-component
          title="Detalhes do processo"
          icon="account"
          class="tile is-child"
          v-if="processoDetalhes.processo"
        >

          <div class="columns">
            <div class="column">
              <b-field label="Referência do Processo">
                <b-input :value="processoDetalhes.processo.Referencia" custom-class="is-static" readonly />
              </b-field>
            </div>
            <div class="column">
              <b-field label="Status">
                <b-tag v-if="parseStatus(processoDetalhes.processo.Status) === 'Ativo'" type="is-success">{{parseStatus(processoDetalhes.processo.Status)}}</b-tag>
                <b-tag v-else type="is-info">{{parseStatus(processoDetalhes.processo.Status)}}</b-tag>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Data de criação">
                <b-input :value="formatDate(processoDetalhes.processo.DTCreated)" custom-class="is-static" readonly />
              </b-field>
            </div>
          </div>
          <hr>
          <div class="columns">
            <div class="column">
              <b-field label="CNPJ">
                <b-input :value="formatCNPJ(processoDetalhes.processo.CNPJ.CNPJ)" custom-class="is-static" readonly />
              </b-field>
            </div>
            <div class="column">
              <b-field label="Descrição CNPJ">
                <b-input :value="processoDetalhes.processo.CNPJ.Descricao" custom-class="is-static" readonly />
              </b-field>
            </div>
          </div>
        </card-component>
    </section>

    <section class="section is-main-section" v-if="processoDetalhes.Produtos">
      <lista-produtos
        v-if="processoDetalhes.processo && processoDetalhes.processo.CNPJ"
        :idCliente="processoDetalhes.processo.CNPJ.IDCliente"
        :produtosLista="processoDetalhes.Produtos"
        ref="produtos"
      />
    </section>

    <b-modal
        v-model="isComponentModalActive"
        has-modal-card
        @close="reset"
    >
      <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Editar Status</p>
        </header>
        <section class="modal-card-body">
          <form @submit.prevent="submitStatus">
            <b-field label="Status do processo" horizontal>
              <b-select
                v-model="form.Status"
                placeholder="Selecione o status"
                required
              >
                <option
                  v-for="(type, index) in statusTypes"
                  :key="index"
                  :value="type.value"
                >
                  {{ type.label }}
                </option>
              </b-select>
            </b-field>
          </form>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-primary is-outlined" @click="reset">Reset</b-button>
            <b-button
              label="Atualizar"
              type="is-primary"
              @click="submitStatus"
              :loading="isLoadingForm"
            />
        </footer>
    </div>
    </b-modal>

  </div>
</template>

<script>
import dayjs from 'dayjs'
import HeroBar from '@/components/HeroBar'
import CardComponent from '@/components/CardComponent'
import ListaProdutos from '@/components/produto/ListaProdutos.vue'
import {
  getProcesso,
  formatCNPJ,
  formatCurrenyBRL,
  notificationError,
  resetFields,
  updateStatusProcesso,
  PODownload,
  reponseHandler
} from '@/core'
import XLSX from 'xlsx'

export default {
  name: 'ClientForm',
  components: {
    CardComponent,
    HeroBar,
    ListaProdutos
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      isLoadingForm: false,
      processoDetalhes: {},
      POData: null,
      PODataFormmated: null,
      loadingPO: false,
      isComponentModalActive: false,
      form: {
        Status: null
      },
      statusTypes: [
        {
          label: 'Ativo',
          value: 0
        },
        {
          label: 'Fechado',
          value: 1
        }
      ]
    }
  },
  computed: {
    heroTitle () {
      if (this.processoDetalhes && this.processoDetalhes.processo) {
        return 'Processo: ' + this.referencia
      } else {
        return 'Detalhe processo'
      }
    },
    referencia () {
      return this.processoDetalhes.processo.Referencia
    }
  },
  methods: {
    formatDate (v) {
      return dayjs(v).format('D MMM, YYYY')
    },
    formatCNPJ (val) {
      return formatCNPJ(val)
    },
    getDetalhes () {
      const id = this.$route.params.id
      getProcesso(id)
        .then((res) => {
          if (res.result === 0) {
            this.processoDetalhes = res.dados
            this.form.Status = Number(res.dados.processo.Status)
          } else {
            reponseHandler(res, () => {}, () => {
              this.processoDetalhes = res
              this.form.Status = Number(res.processo.Status)
            })
          }
        })
        .catch((error) => {
          console.log(error)
          notificationError(this.$buefy.notification)
        })
    },
    parseStatus (statusCode) {
      if (statusCode === '1') return 'Fechado'
      return 'Ativo'
    },
    PODownload () {
      this.loadingPO = true
      const id = this.$route.params.id
      PODownload(id)
        .then(res => {
          console.log(res)
          if (res.result !== 0) notificationError(this.$buefy.notification, res.message)
          if (res.dados) {
            this.POData = res.dados.data
            this.PODataFormmated = this.convertDataToArrayOfArrays(this.POData)
            this.export(this.PODataFormmated)
          }
          this.loadingPO = false
        })
        .catch(err => {
          console.log(err)
          this.PODataFormmated = this.convertDataToArrayOfArrays(this.POData)
          this.export(this.PODataFormmated)
          this.loadingPO = false
          notificationError(this.$buefy.notification)
        })
    },
    export (data) {
      /* convert state to workbook */
      const ws = XLSX.utils.aoa_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Planilha 1')
      /* generate file and send to client */
      XLSX.writeFile(wb, `${this.referencia} - PO.xlsx`)
    },
    convertDataToArrayOfArrays (arrayData) {
      if (arrayData.length > 0) {
        const headerSheet = Object.keys(arrayData[0])
        const data = arrayData.map(item => Object.values(item))
        const finalData = [headerSheet, ...data]
        return finalData
      }
      return []
    },
    formatCurrenyBRL (val) { return formatCurrenyBRL(val) },
    reset () {
      this.form = resetFields(this.form)
      this.isComponentModalActive = false
    },
    submitStatus () {
      const id = this.$route.params.id
      this.isLoadingForm = true
      updateStatusProcesso(this.form.Status, id)
        .then(res => {
          this.$buefy.notification.open({
            message: 'Status alterado com sucesso!',
            type: 'is-success'
          })
          this.isLoadingForm = false
          this.isComponentModalActive = false
          this.reset()
          this.getDetalhes()
        })
        .catch(() => {
          this.isLoadingForm = false
          notificationError(this.$buefy.notification)
        })
    }
  },
  mounted () {
    this.getDetalhes()
  }
}
</script>
