<template>
  <div>
    <form v-if="!this.produtosLista.length" @submit.prevent="buscaProdutos">
      <div class="columns">
        <div class="column is-3">
          <b-field label="Busca">
            <b-input v-model="valorBusca"></b-input>
          </b-field>
        </div>
        <div class="column is-flex is-align-items-self-end">
          <b-field label="Tipo de busca">
            <b-select placeholder="Tipo de busca" v-model="busca">
              <option
                  v-for="option in tipoBusca"
                  :value="option.value"
                  :key="option.value">
                  {{ option.label }}
              </option>
            </b-select>
          </b-field>
          <b-field class="mb-3 ml-2">
            <b-button type="is-primary" @click="buscaProdutos">Buscar</b-button>
          </b-field>
          <b-field class="mb-3 ml-2">
            <b-button @click="limpabusca">Limpar busca</b-button>
          </b-field>
        </div>
      </div>
    </form>
    <card-component title="Produtos" class="has-table has-mobile-sort-spaced mt-5">
      <b-table
        :striped="true"
        :hoverable="true"
        :data="produtos"
        :loading="isLoading"
        :per-page="pagination.perPage"
        :current-page.sync="pagination.currentPage"
        :backend-pagination="true"
        :total="pagination.total"
        :paginated="true"
      >
        <b-table-column label="Cód. Produto" field="cod produto" v-slot="props">
          {{ props.row.Codigo_Produto}}
        </b-table-column>
        <b-table-column label="Descrição" field="Descricao_PTBR" v-slot="props">
          {{ props.row.Descricao_PTBR}}
        </b-table-column>
        <b-table-column label="NCM" field="NCM" v-slot="props">
          {{ props.row.NCM }}
        </b-table-column>
        <b-table-column label="Linha catálago" field="Linha catálogo" v-slot="props">
          {{ props.row.Linha_Catalago }}
        </b-table-column>
        <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
          <div class="buttons is-right">
            <router-link :to="{ name:'produto.detalhes.cliente', params: { id: props.row.IDCliente, idProduto: props.row.IDProduto } }" class="button is-small is-primary">
              <b-icon icon="arrow-right" size="is-small"/>
            </router-link>
          </div>
        </b-table-column>
        <template #empty>
          <p class="has-text-centered">Não há dados para exibir</p>
        </template>
      </b-table>
    </card-component>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import {
  listaProdutos,
  notificationError,
  formatPaginationObj,
  findProdutosbyDescricao,
  findProdutosbyCodigo
} from '@/core'

export default {
  name: 'ListaProdutos',
  components: {
    CardComponent
  },
  data () {
    return {
      isLoading: true,
      produtos: [],
      pagination: formatPaginationObj(),
      tipoBusca: [
        {
          label: 'Descrição',
          value: 'descricao'
        },
        {
          label: 'Cód. Produto',
          value: 'codigo'
        }
      ],
      busca: 'descricao',
      valorBusca: null
    }
  },
  props: {
    idCliente: {
      type: [String, Number],
      required: true
    },
    produtosLista: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    atualPage () {
      return this.pagination.currentPage
    }
  },
  watch: {
    atualPage () {
      if (!this.produtosLista.length) {
        if (this.valorBusca === null) this.listaProdutos()
        else this.buscaProdutos()
      }
    }
  },
  methods: {
    listaProdutos () {
      this.isLoading = true
      listaProdutos(this.idCliente, this.pagination.currentPage, this.pagination.perPage)
        .then(res => {
          this.produtos = res.dados
          this.isLoading = false
          this.pagination = formatPaginationObj(res)
        })
        .catch(() => {
          notificationError(this.$buefy.notification)
          this.isLoading = false
        })
    },
    buscaProdutos () {
      this.isLoading = true
      if (this.busca === 'descricao') {
        findProdutosbyDescricao(this.idCliente, this.valorBusca, this.pagination.currentPage, this.pagination.perPage)
          .then(res => {
            this.produtos = res.dados
            this.isLoading = false
            this.pagination = formatPaginationObj(res)
          })
          .catch(() => {
            console.log('catch')
            notificationError(this.$buefy.notification)
            this.isLoading = false
          })
      } else {
        findProdutosbyCodigo(this.idCliente, this.valorBusca, this.pagination.currentPage, this.pagination.perPage)
          .then(res => {
            this.produtos = res.dados
            this.isLoading = false
            this.pagination = formatPaginationObj(res)
          })
          .catch(() => {
            notificationError(this.$buefy.notification)
            this.isLoading = false
          })
      }
    },
    limpabusca () {
      this.valorBusca = null
      this.listaProdutos()
    }
  },
  created () {
    if (this.produtosLista.length) {
      this.produtos = this.produtosLista.map(item => item.Produto)
      this.isLoading = false
      this.pagination = formatPaginationObj({ TotalItens: this.produtosLista.length })
    } else {
      this.listaProdutos()
    }
  }
}
</script>
